import camelCase from "./utils/camelCase";
import pagination from "./utils/pagination";
import AOS from "aos";
import { slick } from 'slick-carousel';
import matchHeight from 'jquery-match-height';
import "@lottiefiles/lottie-player";

const checkForElement = (element) => {
  if (typeof element != "undefined") {
    return element;
  }
  return false;
};

document.addEventListener("DOMContentLoaded", () => {

	// Match Height Helper Class
	$('.match-height-row').matchHeight({
		byRow: true,
		property: 'min-height',
	});
	$('.match-height').matchHeight({
		byRow: false,
		property: 'min-height',
	});

	// init AOS
  var aosOffset = -100,
  		aosDuration = 400;
	if ($("#mobile-tester").is(":visible")) {
    aosOffset = -50;
    aosDuration = 650;
  }
  
  AOS.init({
    duration: aosDuration,
    easing: "ease-out-cubic",
    startEvent: "load",
    offset: aosOffset,
  });

  // Menu
  $('.menu-trigger').on('click', function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $('.mob-navigation').slideToggle();
  })

  $('.has-children > a').on('click', function(e){
    e.preventDefault();
    $(this).parent('li').find('> .sub-menu-contents').slideToggle();
  });

  // nav search bar
  $('#masthead a[data-search]').on('click', function() {
  	$('#masthead .search-bar[data-search]').slideToggle(150);
  	$('#masthead a[data-search]').toggleClass('active');
  });
  $('#masthead .search-bar[data-search] .close-search').on('click', function() {
  	$('#masthead .search-bar[data-search]').slideUp(150);
  });

  // modal links
  $('a[data-modal]').on('click', function() {
  	let theTarget = $(this).data('modal'),
  			theModal = $('.modal[data-modal="' + theTarget + '"]');

  	theModal.fadeIn(100);
  });

  // modal close
  $('.modal .close-button').on('click', function() {
  	$(this).parents('.modal').fadeOut(100);
  });
  
  // pagination
	$('#pagination').on('click', 'a', function(e){
	  e.preventDefault();
	  $(this).remove();
	  $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
	      $('#ajax-posts').append($('#ajax-loader').html());
	  });
	  $('#pagination').load($(this).attr('href') + ' #pagination>*');
	});

  const blocks = document.querySelectorAll(".husl-block");
  if (!blocks.length) return;

  var blocksArray = [];
  blocks.forEach(async (module) => {
    let name = checkForElement(module.dataset.block);
    if (name) {
      name = camelCase(name);
      if ($.inArray(name, blocksArray) == -1) {
	      blocksArray.push(name);
      }
    } else {
      console.error("No block name found");
      return;
    }
  });

  if (blocksArray.length) {
  	blocksArray.forEach(async (name) => {
		  try {
		    const { Block } = await import(
		      /* webpackChunkName: 'block' */ `./blocks/${name}`
		    );
		    const block = new Block(name);
		    block.init();
		  } catch (error) {
		    console.log(error);
		    console.warn(
		      `You're missing the js file for the ${name} block. \n Create the missing file inside the blocks folder: nylon/assets/js/blocks/${name}.js`
		    );
		  }
		});
  }
  
  /*
  
  Custom Analytics Framework Developed by ScoutAPM (Gemeni Eventing)
  
  PAGE VIEW EVENT SCHEMA: This event should fire every time a navigation change is made by a user on the marketing site. The eventName 
  
  geminiSend(eventName, attributes, annotations)
  
  attributes:
  - path: Path for the page in question, with leading '/'. Value of `location.pathname`.
  - hash_path: The fragment for the page, including leading '#'. Value of `location.hash` or null if not present
  - search: Query parameter string, including leading '?'. Value of `location.search` or null if no string
  - referrer: Value of `document.referrer` or null.
  
  */
  
  var environment = '';   
  var annotations = '';
  var host = location.host;
  var path = location.pathname;
  var hash_path = location.hash;
  var search = location.search;
  var referrer = document.referrer;  
  const attributes = {path:path, hash_path:hash_path, search:search, referrer:referrer};
  
  if(host == 'telemetryhub.com') {
    var environment = 'Production';
  } else if (host == 'telemetryhub.husldigital.com') {
    var environment = 'Development';
  } else {
    var environment = 'Local';
  }
  console.log(attributes);
  geminiInit(environment);
  geminiSend('marketing_routes', attributes, annotations);
  
  //Append cookie URL param on end of all app.telemetryhub.com domains
  $("a[href*='app.telemetryhub.com']").each(function( index ) {
    var this_href = $(this).attr("href");
    var gemeni_cookie = geminiCookie();
    var modified_href = this_href + "?th_cid=" +gemeni_cookie;
    $(this).attr("href", modified_href); 
  });
  
});
